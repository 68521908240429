

































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.password {
  .el-form-item__content {
    line-height: 20px;
  }
}


// .el-select-dropdown__item {
//     height: 69px !important;
//     min-height:34px !important;
//   }